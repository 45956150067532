<template>
  <div>
    <b-alert
      v-if="!isFlat"
      variant="info"
      show
    >
      <div class="alert-body">
        <div class="d-flex justify-content-center">
          <div class="text-center">
            <b-spinner label="Yükleniyor..." />
            <p class="mt-1">
              Veri yükleniyor.
            </p>
            <p class="font-medium-3 font-weight-bolder">
              Lütfen bekleyiniz...
            </p>
          </div>
        </div>
      </div>
    </b-alert>
    <div v-else>
      <div class="d-flex justify-content-center">
        <div class="text-center text-muted">
          <b-spinner label="Yükleniyor..." />
          <div class="mt-1">
            Veri yükleniyor.
          </div>
          <p class="font-weight-bolder">
            Lütfen bekleyiniz...
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { BSpinner, BAlert } from 'bootstrap-vue'

export default {
  name: 'Loading',
  components: {
    BSpinner,
    BAlert,
  },
  props: {
    isFlat: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<template>
  <div>
    <b-row class="mt-5 mb-5">
      <b-col
        cols="12"
        md="12"
        class="text-center"
      >
        <h2>{{ title }}</h2>
        <p>Araç plakasını aşağıdaki alana yazarak araca ait oluşturulmuş ekspertiz kayıtlarını görüntüleyebilirsiniz.</p>
      </b-col>
      <b-col
        cols="12"
        md="12"
      >
        <div class="d-flex justify-content-center">
          <b-input-group class="mt-2 w-75">
            <b-form-input
              v-model="license_plate"
              placeholder="Plaka.."
              @keydown.enter="searchCar"
            />
            <b-input-group-append>
              <b-button
                variant="outline-primary"
                :disabled="searchingCar"
                @click="searchCar"
              >
                <feather-icon icon="SearchIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </b-col>
    </b-row>
    <template v-if="isSearched">
      <hr class="mt-3 mb-2">
      <loading
        v-if="searchingCar"
        :is-flat="true"
      />
      <b-row>

        <b-col
          cols="12"
          md="12"
        >
          <b-list-group>
            <b-list-group-item
              v-for="(item,index) in dataList"
              :key="index"
              :to="'/car/'+item.license_plate+'/'+item.id"
            >
              {{ item.brand }} {{ item.model }} {{ item.hardware }}
              <div>
                <b-badge
                  variant="light-info mr-1"
                >
                  <feather-icon icon="UserIcon" />
                  {{ item.company ? item.company : item.customer }}
                </b-badge>
                <b-badge variant="light-primary mt-1 mr-1">
                  <feather-icon icon="CreditCardIcon" />
                  {{ item.license_plate }}
                </b-badge>
                <b-badge variant="light-warning mt-1 mr-1">
                  <feather-icon icon="CalendarIcon" />
                  {{ moment(item.created).format('DD.MM.YYYY') }}
                </b-badge>
                <b-badge variant="light-secondary mt-1">
                  <feather-icon icon="SettingsIcon" />
                  Model yılı {{ item.model_year }}
                </b-badge>
              </div>
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
    </template>
  </div>
</template>
<script>
import {
  BButton, BCol, BFormInput, BInputGroup, BInputGroupAppend, BRow, BBadge, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import Loading from '@/layouts/components/Loading.vue'

export default {
  name: 'SearchCard',
  components: {
    Loading,
    BCol,
    BRow,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BFormInput,
    BBadge,
    BListGroup,
    BListGroupItem,
  },
  props: {
    title: {
      type: String,
      default: 'Araç Sorgulama',
    },
  },
  data() {
    return {
      license_plate: null,
      isSearched: false,
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['swapcars/getSwapcars']
    },
    searchingCar() {
      return this.$store.getters['swapcars/getDataSearching']
    },
  },
  methods: {
    searchCar() {
      this.isSearched = true // arama yapıldı mı?
      this.$store.dispatch('swapcars/carSearch', {
        license_plate: this.license_plate,
      })
    },
  },
}
</script>
